import WysiwygContent from '../WysiwygContent';

const PageRowItemText = (props) => {
 
  const { text, border, textBoxMaxWidth, textBoxAlign } = props;

  return (
    <div
      className={`page__row__col__text content--html${border === true ? ' with-border' : ''}`}
        style={{
          maxWidth: textBoxMaxWidth ? textBoxMaxWidth + 'rem' : null,
          float: textBoxAlign !== 'center' ? textBoxAlign ?? null : null,
          marginLeft: textBoxAlign === 'center' ? 'auto' : null,
          marginRight: textBoxAlign === 'center' ? 'auto' : null,
        }}
    >
      <div
        className={`page__row__col__text__inner`}
      >
        <WysiwygContent {...props} content={text} />
      </div>
    </div>
  );
};

export default PageRowItemText;
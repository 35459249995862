import { useRef, Fragment, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import DialogBox from './3D/UI/DialogBox';
import ScrollPrompt from './3D/UI/ScrollPrompt';
import ThreeCanvas from './3D/Scene/ThreeCanvas';
import LandingPageContentForeground from './LandingPageForeground';
import LandingPageContentForegroundContents from './LandingPageContentForegroundContents';
import FlowerIcon from '../FlowerIcon';
import gyocLogo from '../../../assets/GYOC_logo.png';
import HoldingPageMailerliteFormWrapper from '../HoldingPageMailerliteFormWrapper';

const LandingPageContent = (props) => {
  const {
    windowHeight, windowWidth, sceneIsLoaded, homepage, isStandardScroll, isPreview, menuIsActive,
    scrollItem, previewItem, setScrollItem, setIsStandardScroll, isHoldingPage,
  } = props;
  const [contentsIsActive, setContentsIsActive] = useState(false);
  const landingPageForeground = useRef();

  return (
    <Fragment>
      <AnimatePresence>
        {
          sceneIsLoaded < 100 &&
          props.previewItem.collection !== 'homepageScrollingRows' &&
          <motion.div
            className="loader"
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ height: windowHeight + 'px' }}
          >
              <p className="loader__text">Loading</p>
              <div className="loader-bar">
                <div
                  className="loader-bar__inner"
                  style={{
                    transform: `scaleX(${1 / 100 * sceneIsLoaded})`
                  }}
                />
              </div>
          </motion.div>
        }
      </AnimatePresence>
      <div
        className={`landing-page__gradient`}
        style={{
          transform: isStandardScroll === true || previewItem.collection === 'homepageScrollingRows' ? 'scaleY(3)' : 'scaleY(1)',
          backgroundColor: isStandardScroll === true ? `rgba(140, 120, 255, 0.6)` : null
        }}
      />
      <AnimatePresence exitBeforeEnter>
        {
          previewItem.collection !== 'homepageScrollingRows' &&
          scrollItem >= 0 && scrollItem < homepage.length && sceneIsLoaded >= 100 &&
          homepage.map(
            (item, index) => (
              scrollItem === index &&
              <DialogBox
                { ...props }
                scrollItem={ scrollItem }
                key={ `dialog${ index }` }
              />
            )
          )
        }
        {
          previewItem.collection !== 'homepageScrollingRows' && sceneIsLoaded >= 100 &&
          isHoldingPage === true && scrollItem === homepage.length - 3 &&
          <HoldingPageMailerliteFormWrapper
            { ...props }
            scrollItem={ scrollItem }
            key="signup form"
          />
        }
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        {
          previewItem.collection !== 'homepageScrollingRows' &&
          isStandardScroll === false &&
          <DialogBox
            {...props}
            scrollItem={-1}
            key={`dialog${-1}`}
          />
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          scrollItem < 0 && previewItem.collection !== 'homepageScrollingRows' && sceneIsLoaded >= 100 &&
          <ScrollPrompt
            {...props}
            scrollItem={scrollItem}
            key="scroll prompt"
          />
        }
      </AnimatePresence>
        <div
          className="background__wrapper"
          style={{
            position: 'fixed',
            height: windowHeight + 'px',
            transform: isStandardScroll === true ? `translateY(${-windowHeight}px)` : `translateY(0)`,
            transition: 'filter 0.4s ease, transform 0.6s ease',
            filter: (scrollItem === -1 && previewItem.collection !== 'homepageScrollingRows') || (menuIsActive === true && windowWidth < 768) ? 'blur(15px)' : 'blur(0)'
          }}
        >
          {
            props.previewItem.collection !== 'homepageScrollingRows' &&
            <ThreeCanvas
              {...props}
              landingPageForeground={landingPageForeground}
            />
          }
        </div>
      <AnimatePresence>
        {
          ((sceneIsLoaded >= 100) || (isPreview === true && previewItem.collection === 'homepageScrollingRows')) &&
          <LandingPageContentForeground
            {...props}
            landingPageForeground={landingPageForeground}
            contentsIsActive={contentsIsActive}
            setContentsIsActive={setContentsIsActive}
          />
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          isStandardScroll === false && sceneIsLoaded >= 100 && (windowWidth >= 768 || menuIsActive === false) &&
          (isHoldingPage === false) &&
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="skip button"
            className="button main-content-button header__menu__link"
            onClick={() => {
              setScrollItem(homepage.length - 2);
              setIsStandardScroll(true);
            }}
          >Skip to main content</motion.button>
        }
        {
          sceneIsLoaded >= 100 &&
          isStandardScroll === true &&
          menuIsActive === false && windowWidth < 768 &&
          <button
            className="landing-page__button--contents"
            onClick={() => setContentsIsActive(!contentsIsActive)}
          >Contents</button>
        }
        {
          sceneIsLoaded >= 100 &&
          isStandardScroll === true &&
          (windowWidth >= 768 || contentsIsActive === true) &&
          <LandingPageContentForegroundContents
            {...props}
            setContentsIsActive={setContentsIsActive}
          />
        }
        {
          sceneIsLoaded >= 100 &&
          (
            (isStandardScroll === false && windowWidth < 768 && menuIsActive === false) ||
            (isStandardScroll === true && windowWidth < 768 && menuIsActive === true) ||
            windowWidth >= 768
          ) &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="gyoc logo"
            className="gyoc-logo__wrapper"
            >
              <a
                className="gyoc-logo__wrapper__link"
                rel="noreferrer"
                target="_blank"
                href="https://growyourown.cloud/"
              >
                <img className="gyoc-logo"
                  src={gyocLogo}
                  alt="GYOC Logo with text which reads 'Grow Your Own Cloud' to the bottom right of the initials" 
                />
              </a>
              
          </motion.div>
        }
        {
          isStandardScroll === false && sceneIsLoaded >= 100 && (windowWidth >= 768 || menuIsActive === false) &&
          <ul className="scroll-indicator-list--3d">
            {
              homepage &&
              homepage[0] &&
              homepage.map(
                (item, index) => (
                  ((isHoldingPage === false && index < homepage.length - 3)
                    ||
                    (isHoldingPage === true && index < homepage.length - 2)
                  ) &&
                  <li key={index} className="scroll-indicator-list--3d__item">
                    <button
                      onClick={() => {
                        setScrollItem(index);
                      }}
                      className="scroll-indicator-list--3d__item__button button--plain"
                    >
                      <FlowerIcon fill={scrollItem === index ? 'white' : 'rgba(255, 255, 255, 0.3)'} />
                    </button>
                  </li>
                )
              )  
            }
          </ul>
        }
      </AnimatePresence>
    </Fragment>
  )
}

export default LandingPageContent;
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';

const WysiwygContent = (props) => {

  const { content, pages } = props;
  const [parsedContent, setParsedContent] = useState('');

  useEffect(() => {
    if (content) {
      const contentSplit = content.split('collection://');
      let contentConcatenated = '';
      for (let i = 0; i < contentSplit.length; i++) {
        if (i > 0 && typeof contentSplit[i] === 'string') {
          const collection = contentSplit[i].substring(0, contentSplit[i].indexOf('/'));
          const stringAfterCollectionHref = contentSplit[i].substring(contentSplit[i].indexOf('"'));
          const id = contentSplit[i].substring(contentSplit[i].indexOf('/') + 1, contentSplit[i].indexOf('"'));
          let slug = '';
          if (collection === 'homepageScrollingRows') {
            slug = `/`;
          } else if (collection === 'homepage') {
              slug = `/`;
          } else if (collection === 'pages') {
            for (let page of pages) {
              if (page._id === id) {
                slug = `/${page.title_slug}`;
              }
            }
          } else {
            slug = '#';
          }
          contentConcatenated += slug + stringAfterCollectionHref;
        } else {
          contentConcatenated += contentSplit[i];
        }
      }
      setParsedContent(contentConcatenated);
    }
  }, [content, pages]);

  if (parsedContent !== '' && typeof parsedContent === 'string') {
    return parse(parsedContent);
  } else {
    return null;
  }
};

export default WysiwygContent;
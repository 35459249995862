import { useEffect } from 'react';

const Preview = (props) => {

  const { setPreviewItem, homepage, setHomepage, homepageScrollingRows, setHomepageScrollingRows, pages, setPages } = props;

  useEffect(() => {
    let prevEvent;

    const handleReceiveMessage = (event) => {
      const { data } = event;
      if (data) {
        const { collection, entry } = data;

        if (collection === 'homepage') {
          setPreviewItem({ ...entry, collection: 'homepage' });
          const homepageArray = [...homepage];
          for (let i = 0;  i < homepageArray.length; i++) {
            if (homepageArray[i]._id === entry._id) {
              homepageArray[i] = { ...entry };
            }
          }
          setHomepage(homepageArray);
        }
        if (collection === 'homepageScrollingRows') {
          setPreviewItem({ ...entry, collection: 'homepageScrollingRows' });
          const homepageScrollingRowsArray = [...homepageScrollingRows];
          for (let i = 0;  i < homepageScrollingRowsArray.length; i++) {
            if (homepageScrollingRowsArray[i]._id === entry._id) {
              homepageScrollingRowsArray[i] = { ...entry };
            }
          }
          setHomepageScrollingRows(homepageScrollingRowsArray);
        }
        if (collection === 'pages') {
          setPreviewItem({
            ...entry,
            collection: 'pages',
            content: { ...entry.content }
          });

          if (prevEvent) {

            const updatedCollection = [...pages];

            for (let i = 0; i < updatedCollection.length; i++) {
              let item = updatedCollection[i];
              if (item._id === entry._id) {
                updatedCollection[i] = {
                  ...entry,
                  collection: 'pages',
                  content: { ...entry.content }
                };
              }
            }

            setPages(updatedCollection);

          }
        }

        prevEvent = event;
      }
    }

      window.addEventListener('message', handleReceiveMessage, false);

      return () => {
        window.removeEventListener('message', handleReceiveMessage, false);
      }
    }, [setPreviewItem, homepage, setHomepage, homepageScrollingRows, setHomepageScrollingRows, pages, setPages]);

  return null
}

export default Preview;
import parse from 'html-react-parser';
import { useState } from 'react';

const PageRowItemDropdown = (props) => {

  const { dropdown } = props;
  const { value } = dropdown;
  const { title, content } = value;
  const [isActive, setIsActive] = useState(false);

  return (
    <li className={`page__row__col__dropdowns__dropdown${isActive ? ' active' : ''}`}>
      <h3 className="page__row__col__dropdowns__dropdown__title">
        <button
          className={`page__row__col__dropdowns__dropdown__title__button${isActive ? ' active' : ''}`}
          onClick={() => setIsActive(!isActive)}
          aria-label="Toggle dropdown"
        >
          {title}
        </button>
      </h3>
      {
        isActive === true &&
        <div className="page__row__col__dropdowns__dropdown__content">
          {content ? parse(content) : ''}
        </div>
      }
    </li>
  )
}

const PageRowItemDropdowns = (props) => {
 
  const { value } = props;
  const { title, dropdowns, border } = value;

  return (
    <div className={`page__row__col__dropdowns content--html${border === true ? ' with-border' : ''}`}>
      {
        title &&
        <h2 className={`page__row__col__dropdowns__title`}>{title}</h2>
      }
      {
        dropdowns &&
        <ul className={`page__row__col__dropdowns__list`}>
          {
            dropdowns.map((dropdown, index) => (
              <PageRowItemDropdown
                {...props}
                dropdown={dropdown}
                key={index}
              />
            ))
          }
        </ul>
      }
    </div>
  );
};

export default PageRowItemDropdowns;
import { useState } from 'react';
import syncSubscriber from '../utils/mailerlite';
import { AnimatePresence, motion } from 'framer-motion';
import parse from 'html-react-parser';

const MailerliteForm = (props) => {

  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressIsValid, setEmailAddressIsValid] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [walletAddressIsValid, setWalletAddressIsValid] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [step, setStep] = useState(0);

  const { formText } = props;
  const { signupFormIntro, signupFormButtonText, signupFormWalletIntro, signupFormPrivacyNote, signupFormWalletPrivacyNote, signupFormSuccessMessage } = formText;

  const handleError = (error) => {
    console.error(error);
    setProcessing(false);
    setFailure(true);
  }

  const handleSuccess = (response) => {
    if (!response.data) {
      handleError(response);
    } else {
      setProcessing(false);
      setFailure(false);
      if (step === 0) {
        setStep(1);
      } else if (step === 1) {
        setSuccess(true);
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (processing === false) {
      if (step === 1) {
        syncSubscriber(emailAddress, walletAddressIsValid === true ? { wallet: walletAddress } : {}, handleSuccess, handleError);
        setProcessing(true);
      } else {
        setStep(1);
      }
    }
  }

  return (
    <div className="form--signup__outer">
      <AnimatePresence exitBeforeEnter>
        {
          success === false &&
          <motion.form
            key="signup form"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onSubmit={handleSubmit}
            className="form--signup"
          >
            <AnimatePresence exitBeforeEnter>
              {
                failure === true &&
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key="error message"
                  className="form--signup__error-message"
                >Something went wrong. Please try again!</motion.p>
              }
              {
                step === 0 &&
                <motion.fieldset
                  key="step 1"
                  className="form--signup__fieldset"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <label htmlFor="email address">{signupFormIntro ?? 'your email address'}</label>
                  <input
                    type="email"
                    name="email address"
                    onChange={event => {
                      const email = event.target.value;
                      setEmailAddress(email);
                      let isValid = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
                      setEmailAddressIsValid(isValid);
                    }}
                    value={emailAddress}
                    placeholder="email address"
                    disabled={processing}
                    autoFocus={true}
                  />
                  {
                    signupFormPrivacyNote &&
                    <div className="form--signup__note">
                      {parse(signupFormPrivacyNote)}
                    </div>
                  }
                </motion.fieldset>
              }
              {
                step === 1 &&
                <motion.fieldset
                  key="step 2"
                  className="form--signup__fieldset"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <label htmlFor="wallet address">{signupFormWalletIntro ?? 'your wallet address'}</label>
                  <input
                    type="text"
                    name="wallet address"
                    onChange={event => {
                      const value = event.target.value;
                      setWalletAddress(value);
                      const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
                      const ENS_NAME_REGEX = /^([a-zA-Z0-9]+\.)+eth$/;
                      let isValid = false;
                      if (ADDRESS_REGEX.test(value) === true || ENS_NAME_REGEX.test(value) === true) {
                        isValid = true;
                      };
                      setWalletAddressIsValid(isValid);
                    }}
                    value={walletAddress}
                    placeholder="wallet address"
                    disabled={processing}
                    autoFocus={true}
                  />
                  {
                    signupFormWalletPrivacyNote &&
                    <div className="form--signup__note">
                      {parse(signupFormWalletPrivacyNote)}
                    </div>
                  }
                </motion.fieldset>
              }
            </AnimatePresence>
            <AnimatePresence>
              {
                emailAddress !== '' && step === 0 && emailAddressIsValid === true &&
                <motion.input
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key="continue"
                  type="submit"
                  className="cta"
                  value="continue"
                  disabled={processing}
                />
              }
              {
                walletAddress !== '' && step === 1 && walletAddressIsValid === true &&
                <motion.input
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key="continue"
                  type="submit"
                  className="cta"
                  value={signupFormButtonText ?? 'sign up'}
                  disabled={processing}
                />
              }
            </AnimatePresence>
          </motion.form>
        }
        {
          success === true &&
          <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="success"
              className="form--signup__success-message"
            >
            {
              signupFormSuccessMessage ?
                parse(signupFormSuccessMessage)
                :
                <p className="form--signup__success-message--default">Thank you 🌱</p>
            }
          </motion.div>
        }
      </AnimatePresence>
    </div>
  )
};

export default MailerliteForm;
import { PerspectiveCamera } from '@react-three/drei';

const Camera = (props) => {
  const { camera, scrollDistance } = props;

  return (
    <PerspectiveCamera
      makeDefault={true}
      ref={camera}
      fov={18}
      position={[0, 0, scrollDistance]}
      near={0.01}
      far={10000}
    >
    </PerspectiveCamera>
  )
}

export default Camera;
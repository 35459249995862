import { useRef } from 'react';
import LazyloadImage from '../LazyloadImage';
import CTA from './CTA';
import PageRowItemText from './PageRowItemText';

const PageRowThumbnail = (props) => {

  const { value, pageScrollAmount, windowHeight, item } = props;
  const { image, text, ctas } = value;
  const { maxWidth } = item;
  const col = useRef();

  return (
    <div
      className={`page__row__thumbnail`}
      ref={col}
      style={{
        transform: pageScrollAmount < (col.current?.offsetTop) - (windowHeight * 0.75) ? `translateY(12%)` : 'translateY(0)',
        maxWidth: maxWidth && maxWidth > 0 && maxWidth !== '' ? `${maxWidth}px` : null,
        flex: maxWidth && maxWidth > 0 && maxWidth !== '' ? `0 0 ${maxWidth}px` : null
      }}
    >
      {
        image?.path &&
        <LazyloadImage image={image} />
      }
      {
        text &&
        <PageRowItemText {...text} />
      }
      {
        ctas &&
        ctas[0] &&
        ctas.map(
          (item, index) => (
            <CTA {...props} key={index} item={item} />
          )
        )
      }
    </div>
  )
}

export default PageRowThumbnail;
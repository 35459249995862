import { useRef } from 'react';
import PageRowColumn from './PageRowColumn';

const PageRowGrid = (props) => {

  const { item, styles } = props;
  const row = useRef();

  return (
    <section
      ref={row}
      className={`columns__wrapper page__row row`}
      style={styles}
    >
      {
        item.row &&
        item.row[0] &&
        item.row.map(
          (column, i) => (
            <PageRowColumn
              {...props}
              field={column.field}
              value={column.value}
              key={i}
              row={row}
            />
          )
        )
      }
    </section>
  )
}

export default PageRowGrid;
const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;

const hexToRgb = (hex) => {
  const rgbHex = hex.replace('#', '').match(/.{1,2}/g);
  return {
    r: parseInt(rgbHex[0], 16),
    g: parseInt(rgbHex[1], 16),
    b: parseInt(rgbHex[2], 16)
  }
}

const deg2rad = degrees => degrees * (Math.PI / 180);

const lerp = (start, end, amount) => {
  return (1-amount)*start+amount*end
}

export { map, hexToRgb, deg2rad, lerp };
import { useRef } from 'react';
import parse from 'html-react-parser';
import { cockpitUrl } from '../../utils/cockpit';
import LazyloadImage from '../LazyloadImage';
import VideoPlayer from '../VideoPlayer';
import CTA from './CTA';
import ProfileThumbnails from './ProfileThumbnails';
import PageRowItemDropdowns from './PageRowItemDropdowns';
import MailerliteFormModule from '../MailerliteFormModule';

const PageRowColumn = (props) => {

  const { field, value, pageScrollAmount, windowWidth, windowHeight } = props;
  const { columnWidthDesktop, columnWidthMobile, columnOffsetDesktop, columnOffsetMobile, columnOffsetTopDesktop,
    columnOffsetTopMobile, image, video, videoUrl, text, border, columnOrderDesktop, textBoxMaxWidth, textBoxAlign } = value;
  const col = useRef();

  return (
    <div
      className={`page__row__col col col-xxs-${columnWidthMobile ? columnWidthMobile : 12} col-md-${columnWidthDesktop ? columnWidthDesktop : 6}
      offset-xxs-${columnOffsetMobile ? columnOffsetMobile : 0} offset-md-${columnOffsetDesktop ? columnOffsetDesktop : 0} page__row__col--${field.name}
      ${columnOrderDesktop ? `order-md-${columnOrderDesktop}` : ''}`}
      ref={col}
      style={{
        transform: pageScrollAmount < (col.current?.offsetTop) - (windowHeight * 0.75)
          ?
          `translateY(12%)` : 'translateY(0)'
      }}
    >
      {
        field.name === 'imageColumn' &&
        <LazyloadImage {...props} image={image ? image : ''} />
      }
      {
        field.name === 'textColumn' && text &&
        <div
          className={`page__row__col__text content--html${border === true ? ' with-border' : ''}`}
          style={{
            maxWidth: textBoxMaxWidth ? textBoxMaxWidth + 'rem' : null,
            float: textBoxAlign !== 'center' ? textBoxAlign ?? null : null,
            marginLeft: textBoxAlign === 'center' ? 'auto' : null,
            marginRight: textBoxAlign === 'center' ? 'auto' : null,
          }}
        >
          <div className={`page__row__col__text__inner`}>
            {parse(text)}
          </div>
        </div>
      }
      {
        field.name === 'videoColumn' &&
        <div>
          <VideoPlayer url={cockpitUrl + '/' + video} {...value} />
          {
            value.caption &&
            <p
              className={`video__caption`}
            >{value.caption}</p>
          }
        </div>
      }
      {
        field.name === 'signupFormColumn' &&
        <MailerliteFormModule formText={value} />
      }
      {
        field.name === 'videEmbedColumn' &&
        <div>
          <VideoPlayer url={videoUrl} {...value} />
        </div>
      }
      {
        field.name === 'profileThumbnails' &&
        <ProfileThumbnails {...props} />
      }
      {
        field.name === 'emptyColumn' &&
        <div
          className="page__row__col--empty"
          style={{
            paddingBottom: windowWidth >= 768 ? columnOffsetTopDesktop + '%' : columnOffsetTopMobile + '%'
          }}
        />
      }
      {
        field.name === 'cta' &&
        <CTA
          {...props}
          item={{
            field: { ...field },
            value: {...value}
          }}
        />
      }
      {
        field.name === 'dropdowns' &&
        <div
          className="page__row__col--dropdowns"
          style={{
            paddingBottom: windowWidth >= 768 ? columnOffsetTopDesktop + '%' : columnOffsetTopMobile + '%',
            textAlign: value.alignLeft === true ? 'left' : null
          }}
        >
          <PageRowItemDropdowns value={value} {...props} />
        </div>
      }
    </div>
  )
}

export default PageRowColumn;
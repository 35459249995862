const FlowerIcon = (props) => {
  const { fill } = props;
  return (
    <svg
      className="flower-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58.96 64.72"
      fill={fill ? fill : 'white'}
    >
      <path d="M1.45,48.54c2.98,5.15,9.57,6.92,14.72,3.94,1.71-.99,3.03-2.37,3.95-3.97v.02h.04c-.93,1.59-1.46,3.43-1.46,5.41,0,5.95,4.83,10.78,10.78,10.78s10.78-4.83,10.78-10.78c0-1.97-.54-3.82-1.46-5.41h.03v-.02c.92,1.59,2.25,2.98,3.96,3.97,5.15,2.98,11.75,1.21,14.72-3.94,2.98-5.15,1.21-11.75-3.94-14.72-1.71-.99-3.57-1.44-5.41-1.44l.02-.03h0c1.83,0,3.69-.46,5.39-1.45,5.15-2.98,6.92-9.57,3.94-14.72-2.98-5.15-9.57-6.92-14.72-3.94-1.7,.98-3.03,2.36-3.94,3.95l-.02-.03h-.02c.92-1.59,1.45-3.42,1.45-5.38,0-5.95-4.83-10.78-10.78-10.78s-10.78,4.83-10.78,10.78c0,1.96,.53,3.8,1.45,5.38h-.02l-.02,.03c-.91-1.59-2.24-2.97-3.94-3.95-5.15-2.98-11.75-1.21-14.72,3.94-2.98,5.15-1.21,11.75,3.94,14.72,1.7,.98,3.56,1.44,5.39,1.44h0l.02,.04c-1.84,0-3.7,.45-5.41,1.44C.24,36.8-1.53,43.39,1.45,48.54h0ZM24.08,23.02c5.15-2.98,11.75-1.21,14.72,3.94,2.98,5.15,1.21,11.75-3.94,14.72-5.15,2.98-11.75,1.21-14.72-3.94-2.98-5.15-1.21-11.75,3.94-14.72Z" />
    </svg>
  )
};

export default FlowerIcon;
import * as THREE from 'three';
import { shaderMaterial } from '@react-three/drei';
import { extend } from '@react-three/fiber';
import { grassVertex } from './grassVertex.glsl.js';
import { grassFragment } from './grassFragment.glsl.js';

const GrassMaterial = shaderMaterial(
  {
    bladeHeight: 2,
    map: null,
    alphaMap: null,
    time: 0,
    fog: true,
    fogColor: new THREE.Color(1.0, 1.0, 1.0),
    fogFar: 320,
    fogNear: 60,
    vFogDepth: 0.8
  },
  grassVertex,
  grassFragment,
  (self) => {
    self.side = THREE.DoubleSide;
    self.transparent = true;
  }
)

extend({ GrassMaterial });
import { useRef, useState, Suspense } from 'react';
import { useProgress } from '@react-three/drei';
import { Canvas, useFrame } from '@react-three/fiber';
import { AnimatePresence, motion } from 'framer-motion';
import ThreeScene from './ThreeScene';

const Loader = (props) => {

  const { setSceneIsLoaded } = props;

  const { progress } = useProgress();

  useFrame(() => {
    setSceneIsLoaded(progress);
    // setSceneIsLoaded(100);
  });

  return null
}

const ThreeCanvas = (props) => {

  const { homepage, windowHeight, scrollAmount, scrollDistance, sceneIsLoaded } = props;
  const fog = useRef();
  const camera = useRef();
  const elapsedTime = useRef(0);
  const targetPosition = useRef({
    x: 0, y: 0, z: 15
  });
  const [handlePositions, setHandlePositions] = useState([]);
  const targetRotation = useRef({
    x: 0, y: 0, z: 0
  });
  const [curve, setCurve] = useState({});

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.4 }}
      style={{ width: '100%', height: windowHeight + 'px' }}
    >
      <AnimatePresence>
        {
          scrollAmount < scrollDistance + windowHeight * 2 &&
          <motion.div
            initial={{ opacity: 0}}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="canvas__wrapper"
          >
            <Canvas>
              <fog attach="fog" color={0xd5d1ff} near={600} far={1000} ref={fog} />
              {
                sceneIsLoaded < 100 &&
                <Loader {...props} />
              }
              {
                homepage[0] &&
                <Suspense fallback={null}>
                  <ThreeScene
                    {...props}
                    camera={camera}
                    elapsedTime={elapsedTime}
                    targetPosition={targetPosition}
                    handlePositions={handlePositions}
                    setHandlePositions={setHandlePositions}
                    targetRotation={targetRotation}
                    curve={curve}
                    setCurve={setCurve}
                  />
                </Suspense>
              }
            </Canvas>
          </motion.div>
        }
      </AnimatePresence>
    </motion.div>
  )
}

export default ThreeCanvas;
import { useRef, useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import LandingPageContent from '../components/LandingPage/LandingPageContent';

const LandingPage = (props) => {

  const { windowHeight, windowWidth, homepage, menuIsActive, navigate, scrollItem, setScrollItem, landingPageRef, isHoldingPage } = props;
  const buttonNav = useRef(false);
  const buttonNavTarget = useRef(null);
  const wheelDisabled = useRef('none');
  const wheelDisabledTimeout = useRef(null);
  const [itemSpace] = useState(400);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [scrollDistance, setScrollDistance] = useState(homepage ? homepage.length * itemSpace : windowHeight);
  const [isStandardScroll, setIsStandardScroll] = useState(false);
  const [signupIsActive, setSignupIsActive] = useState(false);
  const [scrollDistanceBeforeStandardScroll, setScrollDistanceBeforeStandardScroll] = useState(Math.max(windowHeight, scrollDistance - itemSpace * 2));

  const swipeHandlers = useSwipeable({
    onSwipedUp: (e) => {
      if (isStandardScroll === false) {
        if (wheelDisabled.current !== 'higher') {
          wheelDisabled.current = 'higher';
          if (scrollItem < homepage.length - 4) {
            setScrollItem(scrollItem + 1);
          } else {
            if (isHoldingPage === false) {
              setScrollItem(scrollItem + 2);
            } else {
              if (scrollItem < homepage.length - 3) {
                setScrollItem(scrollItem + 1);
              }
            }
          }
        }
      }
      clearTimeout(wheelDisabledTimeout.current);
      wheelDisabledTimeout.current = setTimeout(() => {
        wheelDisabled.current = 'none';
      }, 40);
    },
    onSwipedDown: (e) => {
      if (isStandardScroll === false) {
        if (wheelDisabled.current !== 'lower') {
          wheelDisabled.current = 'lower';
          if (scrollItem > -1) {
            setScrollItem(scrollItem - 1);
          }
        }
      } else {
        if (landingPageRef.current) {
          if (landingPageRef.current.scrollTop - e.deltaY < 0) {
            navigate('/');
            setScrollItem(homepage.length - 4);
          }
        }
      }
      clearTimeout(wheelDisabledTimeout.current);
      wheelDisabledTimeout.current = setTimeout(() => {
        wheelDisabled.current = 'none';
      }, 40);
    }
  });

  useEffect(() => {
    if (isHoldingPage === false) {
      if (scrollItem <= homepage.length - 3) {
        setIsStandardScroll(false);
      } else {
        setIsStandardScroll(true);
      }
    } else {
      if (scrollItem <= homepage.length - 2) {
        setSignupIsActive(false);
      } else {
        setSignupIsActive(true);
      }
    }
  }, [scrollItem, homepage, isHoldingPage]);

  return (
    <div
      className="landing-page__wrapper"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
      {...swipeHandlers}
    >
    <div
      className={`landing-page${isStandardScroll === true ? ' standard-scroll' : ''}`}
      style={{
        height: windowHeight + 'px',
        pointerEvents: menuIsActive === true && windowWidth < 768 ? 'none' : null
      }}
      onWheel={(e) => {
        if (isStandardScroll === false) {
          if (e.deltaY > 0) {
            if (wheelDisabled.current !== 'higher') {
              wheelDisabled.current = 'higher';
              if (isHoldingPage === false) {
                if (scrollItem < homepage.length - 4) {
                  setScrollItem(scrollItem + 1);
                } else {
                  setScrollItem(scrollItem + 2);
                }
              } else {
                if (scrollItem < homepage.length - 3) {
                  setScrollItem(scrollItem + 1);
                }
              }
            }
          } else {
            if (wheelDisabled.current !== 'lower') {
              wheelDisabled.current = 'lower';
              if (scrollItem > -1) {
                setScrollItem(scrollItem - 1);
              }
            }
          }
        } else {
          if (e.deltaY < 0 && landingPageRef.current.scrollTop + e.deltaY < 0) {
            navigate('/');
            setScrollItem(homepage.length - 3);
          }
        }

        clearTimeout(wheelDisabledTimeout.current);
        wheelDisabledTimeout.current = setTimeout(() => {
          wheelDisabled.current = 'none';
        }, 40);
      }}
      onScroll={(event) => {
        let scrollTop = event.target.scrollTop;
        setScrollAmount(scrollTop);
      }}
      ref={landingPageRef}
    >
      <LandingPageContent
        {...props}
        scrollItem={scrollItem}
        setScrollItem={setScrollItem}
        scrollAmount={scrollAmount}
        setScrollAmount={setScrollAmount}
        itemSpace={itemSpace}
        scrollDistance={scrollDistance}
        setScrollDistance={setScrollDistance}
        isStandardScroll={isStandardScroll}
        setIsStandardScroll={setIsStandardScroll}
        signupIsActive={signupIsActive}
        setSignupIsActive={setSignupIsActive}
        scrollDistanceBeforeStandardScroll={scrollDistanceBeforeStandardScroll}
        setScrollDistanceBeforeStandardScroll={setScrollDistanceBeforeStandardScroll}
        buttonNav={buttonNav}
        buttonNavTarget={buttonNavTarget}
      />
    </div>
    </div>
  );
}

export default LandingPage;
import { useEffect } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';

const ThreePath = (props) => {

  const { homepage, scrollItem, targetPosition, targetRotation, curve, setCurve, itemSpace, handlePositions, setHandlePositions } = props;

  useFrame(() => {
    if (curve?.type === "CatmullRomCurve3" && handlePositions[1]) {
      if (typeof curve.getPoint === 'function') {
        const pos = (curve.getPoint(Math.max(0.1, Math.min(0.99, (scrollItem + 1) / homepage.length))));

        if (pos && typeof pos.x !== 'undefined') {
          targetPosition.current = { ...pos, z: pos.z + 60 };
        }
        const rot = (curve.getTangent(Math.max(0.1, Math.min(0.99, scrollItem / homepage.length))));

        if (rot && rot.x) {
          targetRotation.current = { x: rot.x * 1, y: rot.y / 2, z: 0 };
        }
      }
    }
  });

  useEffect(() => {
    const handlePositionsArray = [];

    let count = 0;

    if (homepage[0]) {
      for (let item of homepage) {
        let x = 0;
        if (item.positionX && item.positionX !== '' && typeof item.positionX !== 'undefined') {
          x = parseFloat(item.positionX);
        }

        handlePositionsArray.push(
          new THREE.Vector3(
            x,
            12,
            count * itemSpace
          )
        );
        count++;
      }
      handlePositionsArray.reverse();
    }
    if (handlePositionsArray[1]) {
      let theCurve = new THREE.CatmullRomCurve3(handlePositionsArray);
      setCurve(theCurve);
    }
    setHandlePositions(handlePositionsArray);
  }, [homepage, setCurve, itemSpace, setHandlePositions]);

  return null;
}

export default ThreePath;
import Ground from './Ground';
import Camera from './Camera';
import ThreePath from './ThreePath';
import SceneNavigation from './SceneNavigation';
import HomepageItem from './HomepageItem';
import Grass from './Grass';

const ThreeScene = (props) => {

  const { homepage, scrollItem, setScrollItem, scrollAmount, itemSpace,
    scrollDistance, setScrollDistance, windowHeight, isStandardScroll,
  
    camera, elapsedTime, targetPosition, handlePositions, setHandlePositions, targetRotation, curve, setCurve
  } = props;

  return (
    <group>
      <mesh>
        <sphereBufferGeometry args={[-2000, 128]} />
        <meshBasicMaterial transparent={true} opacity={0.9} />
      </mesh>
      <Camera
        {...props}
        curve={curve}
        itemSpace={itemSpace}
        targetPosition={targetPosition}
        targetRotation={targetRotation}
        elapsedTime={elapsedTime}
        scrollDistance={scrollDistance}
        setScrollDistance={setScrollDistance}
        camera={camera}
      />
      <group position={[0, 0, -30]}>
        {
          isStandardScroll === false &&
          <SceneNavigation
            {...props}
            curve={curve}
            itemSpace={itemSpace}
            scrollAmount={scrollAmount}
            targetPosition={targetPosition}
            targetRotation={targetRotation}
            elapsedTime={elapsedTime}
            scrollDistance={scrollDistance}
            setScrollDistance={setScrollDistance}
            camera={camera}
          />
        }
        {
          homepage &&
          homepage[0] &&
          scrollAmount <= windowHeight + scrollDistance &&
          isStandardScroll === false &&
          homepage.map(
            (item, i) => (
              curve.points &&
              <group key={i}>
                <HomepageItem
                  {...props}
                  item={item}
                  key={i}
                  index={i}
                  curve={curve}
                  itemsLength={homepage.length}
                  elapsedTime={elapsedTime}
                  handlePositions={handlePositions}
                />
              </group>
            )
          )
        }
        {
          homepage &&
          homepage[0] &&
          scrollAmount <= windowHeight + scrollDistance &&
          isStandardScroll === false &&
          homepage.map(
            (item, i) => (
              curve.points &&
              (scrollItem === i - 1 || scrollItem === i - 2) &&
              <group key={i}>
                <Grass
                  key={i}
                  homepage={homepage}
                  index={i}
                  curve={curve}
                  itemsLength={homepage.length}
                  handlePositions={handlePositions}
                />
              </group>
            )
          )
        }
        <directionalLight
          position={[2.5, 8, 5]}
          intensity={1.25}
          castShadow
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
          color={'#00ccff'}
        />
        <directionalLight
          castShadow
          intensity={1.75}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
          color={'peachpuff'}
          position={[20, 30, 200]}
        />
        {
          curve.points &&
          isStandardScroll === false &&
          <Ground
            {...props}
            index={0}
            itemSpace={itemSpace}
            scrollItem={scrollItem}
            setScrollItem={setScrollItem}
            scrollDistance={scrollDistance}
            handlePositions={handlePositions}
            positionZ={0}
            curve={curve}
            itemsLength={homepage.length}
          />
        }
        {
          homepage &&
          homepage[0] &&
          <ThreePath
            {...props}
            camera={camera}
            scrollDistance={scrollDistance}
            targetPosition={targetPosition}
            targetRotation={targetRotation}
            homepage={homepage}
            itemSpace={itemSpace}
            curve={curve}
            setCurve={setCurve}
            handlePositions={handlePositions}
            setHandlePositions={setHandlePositions}
          />
        }
      </group>
    </group>
  );
}

export default ThreeScene;
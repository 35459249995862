import { useRef, Fragment } from 'react';
import { cockpitUrl } from '../../utils/cockpit';
import LazyloadImage from '../LazyloadImage';
import VideoPlayer from '../VideoPlayer';
import CTA from './CTA';
import PageRowItemDropdowns from './PageRowItemDropdowns';
import PageRowItemSignupForm from './PageRowItemSignupForm';
import PageRowItemText from './PageRowItemText';
import ProfileThumbnails from './ProfileThumbnails';

const PageRowColumnComplexContentItem = (props) => {

  const { item, columnOffsetTopDesktop, columnOffsetTopMobile, windowWidth } = props;
  const { field, value } = item;
  const { image, text, video, videoUrl } = value;

  return (
    <Fragment>
      {
        field.name === 'image' &&
        <LazyloadImage {...props} image={image ? image : ''} />
      }
      {
        field.name === 'text' && text && text !== '' &&
        <PageRowItemText {...value} />
      }
      {
        field.name === 'signupForm' &&
        <PageRowItemSignupForm {...props} />
      }
      {
        field.name === 'video' &&
        <div>
          <VideoPlayer url={cockpitUrl + '/' + video} {...value} />
        </div>
      }
      {
        field.name === 'videEmbed' &&
        <div>
          <VideoPlayer url={videoUrl} {...value} />
        </div>
      }
      {
        field.name === 'profileThumbnails' &&
        <ProfileThumbnails {...props} />
      }
      {
        field.name === 'empty' &&
        <div
          className="page__row__col--empty"
          style={{
            paddingBottom: windowWidth >= 768 ? columnOffsetTopDesktop + '%' : columnOffsetTopMobile + '%'
          }}
        />
      }
      {
        field.name === 'cta' &&
        <div
          className="page__row__col--cta"
          style={{
            paddingBottom: windowWidth >= 768 ? columnOffsetTopDesktop + '%' : columnOffsetTopMobile + '%',
            textAlign: value.alignLeft === true ? 'left' : null
          }}
        >
          <CTA value={value} {...props} />
        </div>
      }
      {
        field.name === 'dropdowns' &&
        <div
          className="page__row__col--dropdowns"
          style={{
            paddingBottom: windowWidth >= 768 ? columnOffsetTopDesktop + '%' : columnOffsetTopMobile + '%',
            textAlign: value.alignLeft === true ? 'left' : null
          }}
        >
          <PageRowItemDropdowns value={value} {...props} />
        </div>
      }
    </Fragment>
  )
}

const PageRowColumnComplex = (props) => {

  const { value, pageScrollAmount, windowHeight } = props;
  const { columnWidthDesktop, columnWidthMobile, columnOffsetDesktop, columnOffsetMobile, columnContent, columnOrderDesktop } = value;
  const col = useRef();

  return (
    <div
      className={`page__row__col col col-xxs-${columnWidthMobile ? columnWidthMobile : 12} col-md-${columnWidthDesktop ? columnWidthDesktop : 6}
      offset-xxs-${columnOffsetMobile ? columnOffsetMobile : 0} offset-md-${columnOffsetDesktop ? columnOffsetDesktop : 0}
      ${columnOrderDesktop ? `order-md-${columnOrderDesktop}` : ''}`}
      ref={col}
      style={{
        transform: pageScrollAmount < (col.current?.offsetTop) - (windowHeight * 0.75)
          ?
          `translateY(12%)` : 'translateY(0)'
      }}
    >
      {
        columnContent &&
        columnContent[0] &&
        columnContent.map(
          (item, index) => (
            <PageRowColumnComplexContentItem {...props} key={index} item={item} />
          )
        )
      }
    </div>
  )
}

export default PageRowColumnComplex;
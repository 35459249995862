import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import slugify from 'slugify';

const Footer = (props) => {

  const { options, windowWidth } = props;
  const { footer } = options;

  return (
    <footer className="footer">
      <div className="container-fluid">
        {
          windowWidth >= 768 &&
          <Fragment>
            <div className="row footer__row">
              {
                footer &&
                footer.map(
                  (section, index) => (
                    <div className="col-xxs-12 col-sm-4 footer__section footer__section--headings" key={index}>
                      <h3 className='footer__section__heading'>{section.value.heading}</h3>
                    </div>
                  )
                )
              }
            </div>
            <div className="row footer__row">
              {
                footer &&
                footer.map(
                  (section, index) => (
                    <div className="col-xxs-12 col-sm-4 footer__section footer__section__links" key={index}>
                      {
                        section.value.links &&
                        section.value.links.map(
                          (item, i) => (
                            item.value.linkType === 'external' ?
                              <a
                                key={i}
                                className="footer__section__link"
                                rel="noreferrer"
                                target="_blank"
                                href={item.value.link}
                              >{item.value.text}</a>
                              :
                              <Link
                                to={item.value.linkType === 'page' && item.value.page && item.value.page.display ? `/${slugify(item.value.page.display)}` : '/'}
                                key={i}
                                className="footer__section__link"
                            >{item.value.text}</Link>
                          )
                        )
                      }
                    </div>
                  )
                )
              }
            </div>
          </Fragment>
        }
        {
          windowWidth < 768 &&
          <div className="row">
            {
              footer &&
              footer.map(
                (section, index) => (
                  <div className="col-xxs-12 col-xs-6 footer__section" key={index}>
                    <h3 className='footer__section__heading'>{section.value.heading}</h3>
                    <div className="footer__section__links">
                      {
                        section.value.links &&
                        section.value.links.map(
                          (item, i) => (
                            item.value.linkType === 'external' ?
                              <a
                                key={i}
                                className="footer__section__link"
                                rel="noreferrer"
                                target="_blank"
                                href={item.value.link}
                              >{item.value.text}</a>
                              :
                              <Link
                                to={item.value.linkType === 'page' && item.value.page && item.value.page.display ? `/${slugify(item.value.page.display)}` : '/'}
                                key={i}
                                className="footer__section__link"
                            >{item.value.text}</Link>
                          )
                        )
                      }
                    </div>
                  </div>
                )
              )
            }
          </div>
        }
      </div>
    </footer>
  )
}

export default Footer;
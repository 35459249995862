import { useRef } from 'react';
import PageRowThumbnail from './PageRowThumbnail';
import WysiwygContent from '../WysiwygContent';

const PageRowThumbnails = (props) => {

  const { item, styles } = props;
  const row = useRef();

  return (
    <section
      ref={row}
      className={`columns__wrapper page__row page__row--thumbnails`}
      style={styles}
    >
      <div className="page__row--thumbnails__intro content--html">
        {
          item.introText && item.introText !== '' &&
          <WysiwygContent {...props} content={item.introText} />
        }
      </div>
      <div className="row page__row--thumbnails__row">
        {
          item.thumbnails &&
          item.thumbnails[0] &&
          item.thumbnails.map(
            (column, i) => (
              <PageRowThumbnail
                {...props}
                field={column.field}
                value={column.value}
                key={i}
                row={row}
              />
            )
          )
        }
      </div>
    </section>
  )
}

export default PageRowThumbnails;
import {motion} from 'framer-motion';

const ScrollPrompt = (props) => {

  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      className="scroll-prompt"
    >
      <div className="scroll-prompt__inner">
        <p className="scroll-prompt__text">Scroll or swipe up to continue</p>
      </div>
    </motion.div>
  )
}

export default ScrollPrompt;
import { useState } from 'react';
import Footer from '../Footer';
import PageRows from '../Page/PageRows';

const LandingPageContentForeground = (props) => {

  const { landingPageForeground, homepageScrollingRows, windowHeight, scrollAmount, isStandardScroll, previewItem, windowWidth, menuIsActive, contentsIsActive } = props;
  const [activePageRow, setActivePageRow] = useState(0);

  return (
    <div
      className="landing-page__foreground"
      ref={landingPageForeground}
      style={{
        transform: isStandardScroll === false ? `translateY(${windowHeight}px)` : `translateY(0)`,
        opacity: isStandardScroll === false ? 0 : 1,
        transition: 'filter 0.4s ease, transform 0.6s ease, opacity 0.4s ease',
        filter: menuIsActive === true && windowWidth < 768 ? 'blur(15px)' : 'blur(0)'
      }}
    >
      {
        (isStandardScroll === true || previewItem.collection === 'homepageScrollingRows') &&
        <div
          className="landing-page__foreground__inner"
          style={{
            minHeight: windowHeight + 'px',
            left: contentsIsActive === true && windowWidth < 1200 ? windowWidth > 767 ? 240 + 'px' : windowWidth : null,
            width: contentsIsActive === true && windowWidth < 1200 ? windowWidth > 767 ? windowWidth - 240 + 'px' : windowWidth + 'px' : null
          }}
        >
          <div className="page__inner">
            <div className="page__sections">
              {
                homepageScrollingRows &&
                homepageScrollingRows[0] &&
                homepageScrollingRows.map(
                  (homepageScrollingRow, index) => (
                    <PageRows
                      {...props}
                      key={index}
                      itemIndex={index}
                      page={homepageScrollingRow}
                      pageScrollAmount={scrollAmount}
                      activePageRow={activePageRow}
                      setActivePageRow={setActivePageRow}
                    />
                  )
                )
              }
            </div>
          </div>
        </div>
      }
      <Footer {...props} />
    </div>
  )
}

export default LandingPageContentForeground;

import { useRef, useState } from 'react';
import LandingPageContentForegroundContents from '../components/LandingPage/LandingPageContentForegroundContents';
import LandingPageContentForeground from '../components/LandingPage/LandingPageForeground';

const LandingPagePreview = (props) => {

  const { windowHeight, windowWidth, contentsIsActive, setContentsIsActive, menuIsActive } = props;
  const landingPage = useRef();
  const buttonNav = useRef(false);
  const buttonNavTarget = useRef(null);
  const [scrollItem, setScrollItem] = useState(-1);
  const [itemSpace] = useState(1);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [scrollDistance, setScrollDistance] = useState(0);
  const [scrollDistanceBeforeStandardScroll, setScrollDistanceBeforeStandardScroll] = useState(0);

  return (
    <div
      ref={landingPage}
      className="landing-page standard-scroll"
      style={{
        height: windowHeight + 'px'
      }}
      onWheel={() => buttonNav.current = false}
      onScroll={(event) => {
        let scrollTop = event.target.scrollTop;
        setScrollAmount(scrollTop);
      }}
    >
      {
        menuIsActive === false &&
        windowWidth < 768 &&
        <button
          className="landing-page__button--contents"
          onClick={() => setContentsIsActive(!contentsIsActive)}
        >Contents</button>
      }
      {
        (windowWidth >= 768 || contentsIsActive === true)&&
        <LandingPageContentForegroundContents
          {...props}
          setContentsIsActive={setContentsIsActive}
        />
      }
      <LandingPageContentForeground
        {...props}
        landingPageRef={landingPage}
        scrollItem={scrollItem}
        setScrollItem={setScrollItem}
        scrollAmount={scrollAmount}
        setScrollAmount={setScrollAmount}
        itemSpace={itemSpace}
        scrollDistance={scrollDistance}
        setScrollDistance={setScrollDistance}
        scrollDistanceBeforeStandardScroll={scrollDistanceBeforeStandardScroll}
        setScrollDistanceBeforeStandardScroll={setScrollDistanceBeforeStandardScroll}
        buttonNav={buttonNav}
        buttonNavTarget={buttonNavTarget}
      />
    </div>
  );
}

export default LandingPagePreview;
import { useRef, useState, useEffect } from 'react';
import PageRowGalleryScroll from './PageRowGalleryScroll';
import PageRowGrid from './PageRowGrid';
import PageRowGridComplex from './PageRowGridComplex';
import PageRowFullBleedBackgroundAndForeground from './PageRowFullBleedBackgroundAndForeground';
import PageRowStyles from './PageRowStyles';
import PageRowThumbnails from './PageRowThumbnails';

const PageRow = (props) => {

  const { row, index, itemIndex, windowWidth, pageScrollAmount, setActivePageRow, isStandardScroll, location, navigate } = props;
  const [styles, setStyles] = useState({});
  const pageRow = useRef();

  useEffect(() => {
    if (pageRow.current && location.pathname === '/') {
      if (pageRow.current.getBoundingClientRect().top < 1 && pageRow.current.getBoundingClientRect().bottom > 0) {
        const id = `row${itemIndex ? itemIndex : ''}_${index}`;
        setActivePageRow(id);
        if (window.location.hash !== `#${id}`) {
          navigate(`#${id}`);
        }
      }
    }
  }, [pageScrollAmount, isStandardScroll, setActivePageRow, location.pathname, index, itemIndex, navigate]);

  return (
    <div
      className={`container-fluid page__row__wrapper`}
      ref={pageRow}
      id={`row${itemIndex ? itemIndex : ''}_${index}`}
      style={{
        backgroundColor: styles.backgroundColor,
        paddingTop: index === 0 && windowWidth < 767 ? '90px' : null
      }}
    >
      <PageRowStyles
        row={row}
        setStyles={setStyles}
        windowWidth={windowWidth}
      />
      {
        row.field.name === 'sideScrollingGallery' &&
        row.value.gallery &&
        <PageRowGalleryScroll
          {...props}
          key={index}
          index={index}
          item={row.value}
          styles={styles}
        />
      }
      {
        row.field.name === 'row' &&
        row.value.row &&
        <PageRowGrid
          {...props}
          key={index}
          item={row.value}
          styles={styles}
        />
      }
      {
        row.field.name === 'complexGriddedRow' &&
        row.value.row &&
        <PageRowGridComplex
          {...props}
          key={index}
          item={row.value}
          styles={styles}
        />
      }
      {
        row.field.name === 'rowThumbnails' &&
        row.value.thumbnails &&
        <PageRowThumbnails
          {...props}
          key={index}
          item={row.value}
          styles={styles}
        />
      }
      {
        row.field.name === 'backgroundAndForegroundRow' &&
        <PageRowFullBleedBackgroundAndForeground
          {...props}
          key={index}
          item={row.value}
          styles={styles}
        />
      }
    </div>
  )
}

export default PageRow;
import { Link } from 'react-router-dom';
import AnimatedLogo from './AnimatedLogo';
import Menu from './Menu';

const Header = (props) => {

  const { windowWidth, menuIsActive, setMenuIsActive, setScrollItem, landingPageRef, isHoldingPage } = props;

  return (
    <header className="header">
      <Link
        to="/"
        className="header__link--main"
        onClick={() => {
          if (props.location.pathname === '/') {
            setScrollItem(-1);
            if (landingPageRef.current) {
              landingPageRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
          }
        }}
      >
        <div className="header__logo__wrapper">
          <AnimatedLogo {...props} />
        </div>
      </Link>
      {
        (windowWidth > 768 || menuIsActive === true) &&
        isHoldingPage === false &&
        <Menu {...props} />
      }
      {
        (windowWidth < 768) &&
        isHoldingPage === false &&
        <button
          className="button header__menu__link menu-button"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >{menuIsActive === true ? 'close' : 'menu'}</button>
      }
    </header>
  )
}

export default Header;
import { useState, useEffect } from 'react';
import Page from './Page';

const PagePreview = (props) => {

  const { pages } = props;
  const [pageScrollAmount, setPageScrollAmount] = useState(0);
  const [item, setItem] = useState({});

  useEffect(() => {
    if (!item._id && pages.entries) {
      if (pages.entries[0]) {
        setItem(pages.entries[0]);
      }
    }
  }, [item, pages]);

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        if (event.data?.entry) {
          setItem(event.data.entry);
        }
      },
      false);
  }, []);

  
  return (
    <Page
      {...props}
      page={item}
      pageScrollAmount={pageScrollAmount}
      setPageScrollAmount={setPageScrollAmount}
    />
  );
}

export default PagePreview;
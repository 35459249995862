import { HashLink } from 'react-router-hash-link';
import { motion } from 'framer-motion';

const LandingPageContentForegroundContents = (props) => {

  const { homepageScrollingRows, windowHeight, setContentsIsActive, location } = props;

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        style: 'ease',
        duration: 0.2
      }}
      className="landing-page__foreground__contents"
      style={{ height: windowHeight + 'px' }}
    >
      <ul className="landing-page__foreground__contents__list">
        {
          homepageScrollingRows.map(
            (row, i) => (
              <li
                key={i}
                className={`landing-page__foreground__contents__item${location.hash.indexOf(`#row${i ? i : ''}_`) === 0 ? ' active' : ''}`}
              >
                <HashLink
                  to={`#row${i ? i : ''}_${0}`}
                  onClick={() => setContentsIsActive(false)}
                  smooth
                  className="landing-page__foreground__contents__item__link"
                >{row.title}</HashLink>
              </li>
            )
          )
        }
      </ul>
    </motion.div>
  );
};

export default LandingPageContentForegroundContents;
import { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import { fetchCockpitData } from './utils/cockpit';
import LandingPage from './views/LandingPage';
import Header from './components/Header';
import { AnimatePresence, motion } from 'framer-motion';
import Page from './views/Page';
import Preview from './utils/Preview';
import PagePreview from './views/PagePreview';
import LandingPagePreview from './views/LandingPagePreview';

const Content = (props) => {

  const { windowHeight, location, pages, previewItem, isPreview, isLocalEnvironment, fetchData, isRedirected } = props;

  return (
    <div
      className="app"
      style={{
        height: windowHeight + 'px'
      }}
    >
      <Header {...props} />
      {
        isLocalEnvironment === true &&
        <button
            onClick={fetchData}
            style={{
              position: 'fixed',
              color: 'black',
              zIndex: 999,
              top: 0,
              left: 0
            }}
        >Fetch Data</button>
      }
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route
            path='/'
            element={
              previewItem.collection === 'homepage' || isPreview === false ?
                <LandingPage { ...props } />
                :
                previewItem.collection === 'homepageScrollingRows' ?
                <LandingPagePreview {...props} />
                :
              <div />
            }
          />
          {
            pages &&
            pages[0] &&
            pages.map(
              (page, index) => (
                <Route
                  key={index}
                  path={`/${page.title_slug}`}
                  element={
                    <Page {...props} page={page} />
                  }
                />
              )
            )
          }
          <Route
            key={'page preview'}
            path={'/page-preview'}
            element={
              <PagePreview {...props} />
            }
          />
        </Routes>
      </AnimatePresence>
      {
        isPreview === true &&
        previewItem.collection === 'pages' &&
        <Page
          {...props}
          page={previewItem}
        />
      }
      {
        isPreview === true &&
        <Preview
          {...props}
        />
      }
      <AnimatePresence>
        {
          isRedirected !== 'not redirected' &&
          <motion.div
            className="redirect-loader"
            animate={ { opacity: 1 } }
            exit={ { opacity: 0 } }
            style={ { height: windowHeight + 'px' } }
          />
        }
      </AnimatePresence>
    </div>
  );
}

const App = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const landingPageRef = useRef();
  const [menuIsActive, setMenuIsActive] = useState(false);
  const [userIsTouching, setUserIsTouching] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [homepage, setHomepage] = useState([]);
  const [homepageScrollingRows, setHomepageScrollingRows] = useState([]);
  const [pages, setPages] = useState([]);
  const [options, setOptions] = useState({});
  const [sceneIsLoaded, setSceneIsLoaded] = useState(0);
  const [previewItem, setPreviewItem] = useState({});
  const [isLocalEnvironment] = useState(window.location.origin.indexOf('http://localhost') === 0);
  const [scrollItem, setScrollItem] = useState(-1);
  const [isHoldingPage, setIsHoldingPage] = useState(true);
  const [ holdingPage, setHoldingPage ] = useState({});
  
  const [ isRedirected, setIsRedirected ] = useState('unknown');

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (pathname === '/') {
      setScrollItem(-1);
    }
  }, [pathname]);

  const [isPreview] = useState(location.search.indexOf('preview=true') > -1 ? true : false);

  const wrapper = useRef();
  const main = useRef();

  const handleFetchHomepageData = useCallback((response) => {

    const items = [...response];
    for (let item of items) {
      if (item.positionX && typeof item.positionX !== 'undefined' && item.positionX !== '') {
        item.positionX = Math.max(-12, Math.min(12, parseFloat(item.positionX)))
      } else {
        item.positionX = 0;
      }
    }

    setHomepage([
      ...items,
      {},
      {},
      {}
    ]);
  }, []);

  const handleFetchOptions = useCallback((data) => {
    if (data.redirect === true && data.redirectUrl && data.redirectUrl !== '') {
      window.location.replace(data.redirectUrl);
      setIsRedirected('redirected');
    } else {
      setIsRedirected('not redirected');
    }
    setOptions(data);
    if (data.holdingPage !== true) {
      setIsHoldingPage(false);
      fetchCockpitData('homepageScrollingRows', setHomepageScrollingRows, true);
      fetchCockpitData('pages', setPages, true);
    } else {
      fetchCockpitData('holdingPage', setHoldingPage, false);
    }
  }, []);

  useEffect(() => {
    if (isPreview === true) {
      setIsHoldingPage(false);
    }
  }, [isPreview]);

  useEffect(() => {
    fetchCockpitData('homepage', handleFetchHomepageData, true, null, null, null, null, { _created: -1 });
    if (isPreview === true) {
      fetchCockpitData('options', setOptions, false);
      fetchCockpitData('homepageScrollingRows', setHomepageScrollingRows, true);
      fetchCockpitData('pages', setPages, true);
    } else {
      fetchCockpitData('options', handleFetchOptions, false);
    }
  }, [handleFetchHomepageData, handleFetchOptions, isPreview]);

  const fetchData = () => {
    fetchCockpitData('homepage', handleFetchHomepageData, true, null, null, null, null, {_created:-1});
    fetchCockpitData('options', handleFetchOptions, false);
  }

  useEffect(() => {
    const handleWheel = () => {
      setUserIsTouching(false);
    }
    const handleTouchstart = () => {
      setUserIsTouching(true);
    }

    window.addEventListener('wheel', handleWheel);
    window.addEventListener('touchstart', handleTouchstart);

    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchstart', handleTouchstart);
    }
  }, []);

  useEffect(() => {
    let resizingTimeout;

    const handleWindowResize = () => {
      clearTimeout(resizingTimeout);
      setIsResizing(true);
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
      document.body.style.height = window.innerHeight + 'px';
      resizingTimeout = setTimeout(() => {
        setIsResizing(false);
      }, 200);
    }
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <Content
      { ...windowDimensions }
      { ...{
        main,
        wrapper,
        landingPageRef,
        menuIsActive,
        setMenuIsActive,
        pathname,
        userIsTouching,
        setUserIsTouching,
        isResizing,
        setIsResizing,
        location,
        navigate,
        homepage,
        setHomepage,
        sceneIsLoaded,
        setSceneIsLoaded,
        fetchData,
        homepageScrollingRows,
        setHomepageScrollingRows,
        pages,
        setPages,
        isPreview,
        previewItem,
        setPreviewItem,
        options,
        isLocalEnvironment,
        scrollItem,
        setScrollItem,
        isHoldingPage,
        holdingPage,
        isRedirected
      } }
    />
  );
}

export default App;
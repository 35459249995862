import LazyloadImage from '../LazyloadImage';

const ProfileThumbnail = (props) => {

  const { thumbnail } = props;
  const { value } = thumbnail;
  const { image, title, socialLinks } = value;

  return (
    <div className={`page__row__thumbnail`}>
      {
        image?.path &&
        <div className={`page__row__thumbnail__image__wrapper`}>
          <LazyloadImage image={image} isBackgroundImage={true} />
        </div>
      }
      {
        title &&
        <h5>{title}</h5>
      }
      <div className="page__row__thumbnail__social-links">
        {
          socialLinks &&
          socialLinks[0] &&
          socialLinks.map(
            (item, index) => (
              <a
                className={`profile-thumbnail__social-link profile-thumbnail__social-link--${item.value.type}`}
                rel="noreferrer"
                target="_blank"
                href={item.value.url}
                title={item.value.type === 'custom' ? 'link' : item.value.type}
                key={index}
              >
                {
                  item.value.type === 'custom' &&
                  <LazyloadImage image={item.value.customIcon} />
                }
              </a>
            )
          )
        }
      </div>
    </div>
  )
}

export default ProfileThumbnail;
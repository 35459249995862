// Based on https://codepen.io/al-ro/pen/jJJygQ by al-ro, but rewritten in react-three-fiber
import * as THREE from 'three'
import { useRef, useState, useMemo } from 'react'
import { useFrame, useLoader } from '@react-three/fiber'
import bladeDiffuse from '../../../../../assets/textures/grass-diffuse.jpeg'
import bladeAlpha from '../../../../../assets/textures/grass-alpha.jpeg'
import './GrassMaterial';
import { getAttributeData } from './ThreeInstancingHelpers.js';

const Grass = ({ homepage, index, curve }) => {
  const materialRef = useRef();
  const [options] = useState({ bW: 0.12, bH: 2, joints: 2, instances: 6000, width: 160, depth: 800 });

  const { bW, bH, joints, instances, width, depth } = options;
  const [texture, alphaMap] = useLoader(THREE.TextureLoader, [bladeDiffuse, bladeAlpha])
  const attributeData = useMemo(() => getAttributeData(instances, width, depth), [instances, width, depth]);
  const baseGeom = useMemo(() => new THREE.PlaneBufferGeometry(bW, bH, 1, joints).translate(0, bH / 2, 0), [bW, bH, joints]);

  useFrame((state) => {
    if (materialRef.current) {
      materialRef.current.uniforms.time.value = state.clock.elapsedTime / 4
    }
  });

  return (
    <group
      position={[curve.getPoint(index / homepage.length).x, 0, curve.getPoint(index / homepage.length).z]}
      scale={[1, 1, 1]}
      rotation={[0, 0, 0]}
    >
      <mesh position={[0, 0, 20]}>
        <instancedBufferGeometry index={baseGeom.index} attributes-position={baseGeom.attributes.position} attributes-uv={baseGeom.attributes.uv}>
          <instancedBufferAttribute attachObject={["attributes", "offset"]} args={[new Float32Array(attributeData.offsets), 3]} />
          <instancedBufferAttribute attachObject={["attributes", "orientation"]} args={[new Float32Array(attributeData.orientations), 4]} />
          <instancedBufferAttribute attachObject={["attributes", "stretch"]} args={[new Float32Array(attributeData.stretches), 1]} />
        </instancedBufferGeometry>
        <grassMaterial ref={materialRef} map={texture} alphaMap={alphaMap} fogColor={0x000000} side={THREE.DoubleSide} clipping={true} />
      </mesh>
    </group>
  )
}

export default Grass;
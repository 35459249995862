// import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
// import groundColo from '../../assets/textures/ground/grass/Grass_001_COLOR.jpg';
// import groundNorm from '../../assets/textures/ground/grass/Grass_001_NORM.jpg';
// import groundRoug from '../../assets/textures/ground/grass/Grass_001_ROUGH.jpg';

const GroundMaterial = (props) => {

  const { isArray } = props;
  // const map = useTexture(groundColo);
  // const normal = useTexture(groundNorm);
  // const roughness = useTexture(groundRoug);

  return (
    <meshPhysicalMaterial
      attachArray={isArray === true ? "material" : null}
      color={0x6677ff}
      needsUpdate={true}
      side={THREE.DoubleSide}
      reflectivity={0.1}
      metalness={0.1}
      roughness={0.9}
      normalScale={0.09}
      flatShading={false}
    >
      {/* <primitive
        attach="map"
        object={map}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        needsUpdate={true}
        repeat={new Vector2(width / 3, depth / 3)}
      />
      <primitive
        attach="normalMap"
        object={normal}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        needsUpdate={true}
        repeat={new Vector2(width / 3, depth / 3)}
      />
      <primitive
        attach="roughnessMap"
        object={roughness}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        needsUpdate={true}
        repeat={new Vector2(width / 9, depth / 9)}
      /> */}
    </meshPhysicalMaterial>
  );
}

export default GroundMaterial;
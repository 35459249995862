import { useRef, useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { cockpitUrl, getImage } from '../../utils/cockpit';
import parse from 'html-react-parser';

const PageRowFullBleedBackgroundAndForeground = (props) => {

  const { item, styles, windowWidth } = props;
  const { backgroundVideo, backgroundImage, foregroundText } = item;
  const row = useRef();
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const [videoIsLoaded, setVideoIsLoaded] = useState(false);
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const video = useRef();
  const image = useRef();
  const foregroundTextWrapper = useRef();
  const [imageSrc, setImageSrc] = useState('');
  const [minHeight, setMinHeight] = useState(0);

  useEffect(() => {
    if (backgroundImage && backgroundImage.path && backgroundImage.path !== '') {
      getImage(backgroundImage.path, 2500, 2500, 60, setImageSrc);
    }
  }, [backgroundImage]);

  useEffect(() => {
    let width = 0, height = 0;
    if (videoIsPlaying === true && videoIsLoaded === true) {
      width = video.current.videoWidth;
      height = video.current.videoHeight;
    } else if (imageIsLoaded === true) {
      width = image.current.naturalWidth;
      height = image.current.naturalHeight;
    }
    if (typeof width === 'number' && typeof height === 'number' && width > 0 && height > 0) {
      setMinHeight(height / width * windowWidth);
      if (foregroundTextWrapper.current) {
        if (foregroundTextWrapper.current.offsetHeight + 40 > height / width * windowWidth) {
          row.current.style.height = foregroundTextWrapper.current.offsetHeight + 40 + 'px';
        } else {
          row.current.style.height = height / width * windowWidth + 'px';
        }
      } else {
        row.current.style.height = height / width * windowWidth + 'px';
      }
    }
  }, [videoIsPlaying, videoIsLoaded, imageIsLoaded, windowWidth]);

  return (
    <section
      className="page__section--full-bleed--background-and-foreground"
      onClick={() => {
        if (video.current) {
          video.current.play()
            .catch();
        }
      }}
      ref={row}
      style={{
        ...styles,
        minHeight: minHeight + 'px',
        height: minHeight + 'px',
        margin: '0 -20px'
      }}
    >
      <AnimatePresence>
      {
        imageSrc !== '' &&
        (backgroundVideo && backgroundVideo.path && backgroundVideo.path !== '' && videoIsLoaded === true && videoIsPlaying === true) !== true &&
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`page__section--full-bleed--background-and-foreground__img${imageIsLoaded === true ? ' loaded' : ''}`}
          onLoad={() => setImageIsLoaded(true)}
          src={imageSrc}
          ref={image}
          alt={backgroundImage.meta?.title ? backgroundImage.meta.title : 'background'}
        />
      }
      </AnimatePresence>
      {
        backgroundVideo && backgroundVideo.path && backgroundVideo.path !== '' &&
        <video
          className={`page__section--full-bleed--background-and-foreground__video${videoIsPlaying === true ? ' playing' : ''}`}
          src={cockpitUrl + '/storage/uploads' + backgroundVideo.path}
          muted={true}
          autoPlay={true}
          onPlay={() => {
            setVideoIsPlaying(true);
            setVideoIsLoaded(true);
          }}
          onPause={() => setVideoIsPlaying(false)}
          onLoadedMetadata={() => setVideoIsLoaded(true)}
          onCanPlay={() => setVideoIsLoaded(true)}
          ref={video}
          loop={true}
        />
      }
      {
        foregroundText && foregroundText !== '' &&
        <div
            ref={foregroundTextWrapper}
          className="page__section--full-bleed--background-and-foreground__text--foreground content--html"
        >
          {parse(foregroundText)}
        </div>
      }
    </section>
  )
}

export default PageRowFullBleedBackgroundAndForeground;
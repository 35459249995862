import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const CTA = (props) => {
  const { item, pages } = props;
  const { value } = item;
  const { text, link, page, linkType } = value;
  const [pagePath, setPagePath] = useState('');
  
  useEffect(() => {
    if (page?._id) {
      for (let pageItem of pages) {
        if (pageItem._id === page._id) {
          setPagePath(`/${pageItem.title_slug}`);
        }
      }
    }
  }, [page, pages]);

  if (linkType === 'external') {
    return (
      <a
        className="cta"
        href={link}
        rel="noreferrer"
        target="_blank"
      >
        <p>{text}</p>
      </a>
    );
  } else if (linkType === 'home') {
    return (
      <Link
        to="/"
        className="cta"
      >
        <p>{text}</p>
      </Link>
    );
  } else if (linkType === 'page' && pagePath !== '') {
    return (
      <Link
        to={pagePath}
        className="cta"
      >
        <p>{text}</p>
      </Link>
    );
  } else {
    return null;
  }
};

export default CTA;
import { Fragment } from 'react';
import PageRow from './PageRow';

const PageRows = (props) => {

  const { page } = props;
  const { content } = page;

  return (
    <Fragment>
      {
        content &&
        content[0] &&
        content.map(
          (row, index) => (
            <PageRow {...props} key={index} row={row} index={index} />
          )
        )
      }
    </Fragment>
  )
}

export default PageRows;
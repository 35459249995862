import ProfileThumbnail from './ProfileThumbnail';

const ProfileThumbnails = (props) => {

  const { value } = props;
  const { thumbnails, centered } = value;

  return (
    <div className={`row row--profile-thumbnails${centered === true ? ' centered' : ''}`}>
      {
        thumbnails &&
        thumbnails[0] &&
        thumbnails.map(
          (thumbnail, i) => (
            <ProfileThumbnail
              {...props}
              key={i}
              thumbnail={thumbnail}
            />
          )
        )
      }
    </div>
  )
}

export default ProfileThumbnails;
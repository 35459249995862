import parse from 'html-react-parser';
import { AnimatePresence, motion } from 'framer-motion';

const DialogBox = (props) => {

  const { windowWidth, homepage, scrollItem } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={scrollItem}
      className="landing-page__dialog-box"
    >
      <AnimatePresence exitBeforeEnter>
        {
          homepage[scrollItem] && homepage[scrollItem].text &&
          <motion.div
            key={scrollItem}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="inner"
          >
            {windowWidth > 767 ? parse(homepage[scrollItem].text) : parse(homepage[scrollItem].text.replace('<br />', ' '))}
          </motion.div>
        }
      </AnimatePresence>
    </motion.div>
  )
}

export default DialogBox;
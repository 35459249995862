import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Menu = (props) => {

  const { setMenuIsActive, options, pages, isHoldingPage } = props;

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="header__menu"
    >
      {
        isHoldingPage === false &&
        <a
          href={options.lowCarbonUrl ?? 'https://low-carbon.nfp.garden'}
          className="header__menu__link button--low-carbon-mode"
        >low carbon</a>
      }
      {
        pages &&
        pages[0] &&
        pages.map(
          (page, index) => (
            page.linkToMainMenu === true &&
            <Link
              key={index}
              onClick={() => setMenuIsActive(false)}
              to={`/${page.title_slug}`}
              className="header__menu__link"
            >{page.title}</Link>
          )
        )
      }
    </motion.nav>
  )
}

export default Menu;
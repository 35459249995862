import { useState, useEffect, useRef } from 'react';
import PageGalleryImage from './PageGalleryImage';

const PageRowGalleryScroll = (props) => {

  const { item, windowWidth, windowHeight, pageScrollAmount, index, styles } = props;
  const { gallery } = item;
  const [galleryItems, setGalleryItems] = useState([]);

  const galleryRow = useRef();

  useEffect(() => {
    if (gallery) {
      const length = gallery.length;
      let remainingWidth = 400;
      const newArray = [];
      for (let image of gallery) {

        const newImage = { ...image };
        newImage.width = Math.round(Math.min(((100 / length)), remainingWidth));
        remainingWidth -= newImage.width;
        newArray.push(newImage);
      }
      setGalleryItems(newArray);
    }
  }, [gallery]);

  return (
    <div
      className="page__row--gallery__wrapper"
      style={styles}
    >
      <div
        ref={galleryRow}
        className="page__row--gallery"
        style={{
          transform: pageScrollAmount < galleryRow.current?.offsetTop - (windowHeight / 2)
            ?
            `translateY(12%)` : 'translateY(0)'
        }}
      >
        <div
          className="page__row--gallery__inner"
          style={{
            width: windowWidth + windowHeight + 'px',
            transform: index % 2 === 0 ?
              `translateX(-${(pageScrollAmount + (windowHeight) - galleryRow.current?.offsetTop) / 2}px)`
              :
              `translateX(${(pageScrollAmount + (windowHeight) - galleryRow.current?.offsetTop) / 2 - windowHeight}px)`
          }}
        >
        {
          galleryItems &&
          galleryItems[0] &&
          galleryItems.map(
            (image, i) => (
              <PageGalleryImage image={image} key={i} />
            )
          )
        }
        {
          galleryItems &&
          galleryItems[0] &&
          galleryItems.map(
            (image, i) => (
              <PageGalleryImage image={image} key={i} />
            )
          )
        }
        </div>
      </div>
    </div>
  )
}

export default PageRowGalleryScroll;
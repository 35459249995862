import { useEffect } from 'react';
import { useRef, useState } from 'react';
import HomepageModel from './HomepageModel';

const HomepageItem = (props) => {

  const { item, curve, index, itemsLength, scrollItem, previewItem, isStandardScroll } = props;
  const { model, _id } = item;
  const inner = useRef();
  const [tangent, setTangent] = useState(curve.getTangent(((index + 1)) / itemsLength));
  const [position, setPosition] = useState(curve.getPoint(((index + 1)) / itemsLength));

  useEffect(() => {
    setTangent(curve.getTangent(((index + 1)) / itemsLength));
    setPosition(curve.getPoint(((index + 1)) / itemsLength));
  }, [curve, index, itemsLength]);

  if (_id) {
    return (
      <group
        position={[position.x + item.positionX, -1, position.z]}
        scale={[1, 1, 1]}
        rotation={[0, tangent.y, 0]}
      >
        <group ref={inner}>
          {
            model && model !== '' && (model.indexOf('.gltf') > -1 || model.indexOf('.glb') > -1) && previewItem.collection !== 'homepageScrollingRows' && isStandardScroll === false &&
            <HomepageModel
              {...props}
              modelPath={model}
              active={index === scrollItem}
            />
          }
          {/* <mesh>
            <sphereBufferGeometry args={[2, 24]} />
            <meshBasicMaterial color="hotpink" />
          </mesh> */}
        </group>
      </group>
    );
  } else {
    return null;
  }

}

export default HomepageItem;
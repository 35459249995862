export const grassFragment = /* glsl */`
precision mediump float;
uniform sampler2D map;
uniform sampler2D alphaMap;
varying vec2 vUv;
varying float frc;

uniform vec3 fogColor;
varying float vFogDepth;
uniform float fogNear;
uniform float fogFar;

void main() {
  //Get transparency information from alpha map
  float alpha = texture2D(alphaMap, vUv).r;
  //If transparent, don't draw
  if (alpha < 0.15) discard;
  //Get colour data from texture
  vec4 col = vec4(texture2D(map, vUv));
  //Add more green towards root
  col = mix(vec4(0.3, 0.6, 0.3, 1.0), col, frc);
  //Add a shadow towards root
  col = mix(vec4(0.4, 0.1, 0.4, 1.0), col, frc);
  float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
  gl_FragColor = col;
	gl_FragColor.rgb = mix( gl_FragColor.rgb, vec3(0.8, 0.75, 1.0), fogFactor );
}`
import LazyloadImage from '../LazyloadImage';

const PageGalleryImage = (props) => {

  const { image } = props;

  return (
    <div
      className="page__gallery__image__wrapper"
      style={{
        width: image.width + '%'
      }}
    >
      <LazyloadImage {...props} image={image} />
    </div>
  )
}

export default PageGalleryImage;
const syncSubscriber = (email, fields, successCallback, errorCallback) => {
  fetch(`https://connect.mailerlite.com/api/subscribers`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_MAILERLITE_API_KEY}`
    },
    body: JSON.stringify({
      "email": email,
      "fields": fields ? fields : null,
      "groups": fields.wallet ? ['73941638967199007'] : ['73938684875900239']
    })
  })
  .then(response => response.json())
  .then(response => {
    successCallback && successCallback(response);
    if (!successCallback) {
      console.log('success!');
    }
  })
  .catch(error => {
    errorCallback && errorCallback(error);
    if (!errorCallback) {
      console.log(error);
    }
  });
};

export default syncSubscriber;
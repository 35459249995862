import MailerliteFormModule from '../MailerliteFormModule';

const PageRowItemSignupForm = (props) => {
 
  const { item } = props;

  return (
    <div
      className={`page__row__col__signupForm content--html`}
    >
      <MailerliteFormModule {...props} formText={item.value} />
    </div>
  );
};

export default PageRowItemSignupForm;
import { useRef, useState, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { cockpitUrl } from '../../../../utils/cockpit';
import { deg2rad, lerp } from '../../../../utils/helpers';

const HomepageModel = (props) => {

  const { modelPath, item, active } = props;
  const { rotationX, rotationY, rotationZ, scale } = item;
  const model = useGLTF(cockpitUrl + '/' + modelPath);
  const [modelClone, setModelClone] = useState({});
  const mesh = useRef();
  const group = useRef();
  const [modelRotation, setModelRotation] = useState([0, 0, 0]);
  const [modelScale, setModelScale] = useState([1, 1, 1]);

  useEffect(() => {
    let rotationObject = {};
    let scaleNumber = 1;
    if (rotationX && typeof rotationX !== 'undefined' && rotationX !== '') {
      rotationObject.x = deg2rad(parseFloat(rotationX));
    } else {
      rotationObject.x = 0;
    }
    if (rotationY && typeof rotationY !== 'undefined' && rotationY !== '') {
      rotationObject.y = deg2rad(parseFloat(rotationY));
    } else {
      rotationObject.y = 0;
    }
    if (rotationZ && typeof rotationZ !== 'undefined' && rotationZ !== '') {
      rotationObject.z = deg2rad(parseFloat(rotationZ));
    } else {
      rotationObject.z = 0;
    }
    setModelRotation([rotationObject.x, rotationObject.y, rotationObject.z]);
    if (scale && typeof scale !== 'undefined' && scale !== '') {
      scaleNumber = parseFloat(scale);
    } else {
      scaleNumber = 0;
    }
    setModelScale(scaleNumber);
    if (model.scene) {
      setModelClone(model.scene.clone());
    }
  }, [scale, rotationX, rotationY, rotationZ, model]);

  const targetRotation = useRef(0);

  useFrame(({ clock, mouse }) => {
    if (group.current && active === true) {
      targetRotation.current = deg2rad(Math.sin(clock.elapsedTime / 5) * 60 - 30) + mouse.x * 0.5;
      group.current.rotation.y = lerp(group.current.rotation.y, targetRotation.current, 0.2);
    } else {
      targetRotation.current = deg2rad(Math.sin(clock.elapsedTime / 5) * 60 - 30);
      group.current.rotation.y = targetRotation.current;
    }
  });

  if (modelClone.undefined !== true) {
    return (
      <group
        ref={group}
      >
        <mesh
          ref={mesh}
          scale={modelScale}
          rotation={modelRotation}
          position={[0, -1, 0]}
        >
          <primitive object={modelClone} />
        </mesh>
      </group>
    );
  } else {
    return null;
  }
}

export default HomepageModel;
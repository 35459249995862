import { useRef, useState } from 'react';
import Footer from '../components/Footer';
import PageRows from "../components/Page/PageRows";

const Page = (props) => {

  const { windowHeight, windowWidth, page, menuIsActive } = props;
  const [pageScrollAmount, setPageScrollAmount] = useState(0);
  const prevScrollAmount = useRef(0);

  const handleScroll = (e) => {
    setPageScrollAmount(e.target.scrollTop);
    prevScrollAmount.current = e.target.scrollTop;
  }

  return (
    <div
      style={{
        height: windowHeight + 'px',
        backgroundColor: page.backgroundColor ? page.backgroundColor : null,
        transition: 'filter 0.4s ease, transform 0.4s ease',
        filter: menuIsActive === true && windowWidth < 768 ? 'blur(15px)' : 'blur(0)'
      }}
      className={`page`}
      onScroll={handleScroll}
    >
      <div className="page__inner">
        <div className="page__sections">
          <PageRows
            {...props}
            scrollAmount={pageScrollAmount}
          />
        </div>
      </div>
      <Footer {...props} />
    </div>
  )
}

export default Page;
import { useState } from 'react';
import syncSubscriber from '../utils/mailerlite';
import { AnimatePresence, motion } from 'framer-motion';
import parse from 'html-react-parser';

const MailerliteFormModule = (props) => {

  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressIsValid, setEmailAddressIsValid] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [walletAddressIsValid, setWalletAddressIsValid] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [step, setStep] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const { formText } = props;
  const { cta, signupFormIntro, signupFormButtonText, signupFormWalletIntro, signupFormPrivacyNote, signupFormWalletPrivacyNote,
    textAlign, signupFormSuccessMessage, collectWalletDetails } = formText;

  const handleError = (error) => {
    console.error(error);
    setProcessing(false);
    setFailure(true);
  }

  const handleSuccess = (response) => {
    if (!response.data) {
      handleError(response);
    } else {
      setProcessing(false);
      setFailure(false);
      if (step === 0 && collectWalletDetails === true) {
        setStep(1);
      } else {
        setSuccess(true);
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (processing === false) {
      if (step === 1 || collectWalletDetails !== true) {
        syncSubscriber(emailAddress, walletAddressIsValid === true ? { wallet: walletAddress } : {}, handleSuccess, handleError);
        setProcessing(true);
      } else {
        setStep(1);
      }
    }
  }

  return (
    <div className={`form--signup__outer${textAlign ? ' ' + textAlign : ''} form--signup__outer--module`}>
      <AnimatePresence exitBeforeEnter>
        {
          isActive === false &&
          <motion.button
            className="form--signup__cta cta"
            onClick={() => setIsActive(true)}
          >
            {cta ?? 'subscribe to the mailing list'}   
          </motion.button>
        }
        {
          success === false && isActive === true &&
          <motion.form
            key="signup form"
            initial={{ opacity: 0 }}
            animate={{ opacity: processing === false ? 1 : 0.4 }}
            exit={{ opacity: 0 }}
            onSubmit={handleSubmit}
            className="form--signup"
          >
            <AnimatePresence exitBeforeEnter>
              {
                failure === true &&
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key="error message"
                  className="form--signup__error-message"
                >Something went wrong. Please try again!</motion.p>
              }
              {
                (step === 0 || collectWalletDetails !== true) &&
                <motion.fieldset
                  key="step 1"
                  className="form--signup__fieldset"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {
                    signupFormIntro && signupFormIntro !== '' &&
                    <label htmlFor="email address">{signupFormIntro ?? 'your email address'}</label>
                  }
                  <input
                    type="email"
                    name="email address"
                    onChange={event => {
                      const email = event.target.value;
                      let isValid = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
                      setEmailAddressIsValid(isValid);
                      setEmailAddress(email);
                    }}
                    value={emailAddress}
                    placeholder="email address"
                    disabled={processing}
                    autoFocus={true}
                  />
                  {
                    signupFormPrivacyNote &&
                    <div className="form--signup__note">
                      {parse(signupFormPrivacyNote)}
                    </div>
                  }
                </motion.fieldset>
              }
              {
                step === 1 && collectWalletDetails === true &&
                <motion.fieldset
                  key="step 2"
                  className="form--signup__fieldset"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {
                    signupFormWalletIntro && signupFormWalletIntro !== '' &&
                    <label htmlFor="email address">{signupFormWalletIntro ?? 'your email address'}</label>
                  }
                  <input
                    type="text"
                    name="wallet address"
                    onChange={event => {
                      const value = event.target.value;
                      setWalletAddress(value);
                      const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
                      const ENS_NAME_REGEX = /^([a-z0-9]+\.)+eth$/;
                      let isValid = false;
                      if (ADDRESS_REGEX.test(value) === true || ENS_NAME_REGEX.test(value) === true) {
                        isValid = true;
                      };
                      setWalletAddressIsValid(isValid);
                    }}
                    value={walletAddress}
                    placeholder="your wallet address"
                    disabled={processing}
                  />
                  {
                    signupFormWalletPrivacyNote &&
                    <div className="form--signup__note">
                      {parse(signupFormWalletPrivacyNote)}
                    </div>
                  }
                </motion.fieldset>
              }
            {
              emailAddress !== '' && emailAddressIsValid === true &&
              step === 0 && collectWalletDetails === true &&
              <motion.input
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                type="submit"
                className="cta"
                key="continue"
                value="continue"
                disabled={processing}
              />
            }
            {
              (
                (walletAddress !== '' && walletAddressIsValid === true && step === 1)
                || (collectWalletDetails !== true && emailAddressIsValid === true)
              ) &&
              <motion.input
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                disabled={processing}
                type="submit"
                className="cta"
                key="submit"
                value={signupFormButtonText ?? 'sign up'}
              />
            }
            </AnimatePresence>
          </motion.form>
        }
        {
          success === true &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="success"
            className="form--signup__success-message"
          >
            {
              signupFormSuccessMessage ?
                parse(signupFormSuccessMessage)
                :
                <p>Thank you 🌱</p>
            }
          </motion.div>
        }
      </AnimatePresence>
    </div>
  )
};

export default MailerliteFormModule;
// Based on https://codepen.io/al-ro/pen/jJJygQ by al-ro, but rewritten in react-three-fiber
import * as THREE from 'three'
import { useRef, useMemo } from 'react'
import SimplexNoise from 'simplex-noise'
import { Geometry } from 'three/examples/jsm/deprecated/Geometry'
import './GrassMaterial';
import GroundMaterial from './GroundMaterial';

const simplex = new SimplexNoise(Math.random)

const Grass = ({ width = 400, curve }) => {
  const terrain = useRef();
  const groundGeo = useMemo(() => {
    const geo = new Geometry().fromBufferGeometry(new THREE.TubeGeometry(curve, 200, 300, 600, false))
    geo.verticesNeedUpdate = true
    geo.lookAt(new THREE.Vector3(0, 1, 0))
    for (let i = 0; i < geo.vertices.length; i++) {
      const v = geo.vertices[i]
      v.z = getYPosition(v.x, v.y);
    }
    geo.computeVertexNormals()
    return geo.toBufferGeometry()
  }, [curve]);

  return (
    <group
      position={[0, -2, 0]}
      scale={[1, 1, 1]}
      rotation={[0, 0, 0]}
    >
      {
        <mesh position={[0, 0, 0]} geometry={groundGeo} scale={[1, 1, 1]} rotation={[Math.PI / 2, 0, 0]} ref={terrain}>
          <GroundMaterial
            width={width / 4}
            depth={width / 4}
          />
        </mesh>
      }
    </group>
  )
}

function getYPosition(x, z) {
  var y = 2 * simplex.noise2D(x / 50, z / 50)
  y += 4 * simplex.noise2D(x / 100, z / 100)
  y += 0.2 * simplex.noise2D(x / 10, z / 10)
  return y
}

export default Grass;
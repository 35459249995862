import { useState, useEffect } from 'react';

const AnimatedLogo = (props) => {

  const { sceneIsLoaded, location } = props;
  const [isActive, setIsActive] = useState(0);

  useEffect(() => {
    if (sceneIsLoaded !== 100 && location.pathname === '/') {
      setIsActive(1);
    } else {
      setIsActive(2);
    }
  }, [sceneIsLoaded, location.pathname]);

  return (
    <svg
      className={`logo${isActive > 0 ? ' active' : ''}${isActive > 1 ? ' active--2' : ''}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 457.96 169.51"
      // onClick={() => setIsActive(!isActive)}
    >
      <g className="logo__nfp">
        <polygon points="59.04 89.24 59.04 99.8 16.44 28.16 0 28.16 0 112.16 10.92 112.16 10.92 48.92 10.92 38.72 54.84 112.16 70.08 112.16 70.08 28.16 59.04 28.16 59.04 89.24" />
        <polygon points="79.68 112.16 90.6 112.16 90.6 73.4 132.36 73.4 132.36 64.28 90.6 64.28 90.6 37.28 134.4 37.28 134.4 28.16 79.68 28.16 79.68 112.16" />
        <path d="M173.28,28.16h-32.76V112.16h10.92v-36.36h21.84c15.24,0,28.8-5.4,28.8-23.76s-14.16-23.88-28.8-23.88Zm-.12,38.52h-21.72v-29.4h21.72c12.84,0,17.76,5.76,17.76,14.76s-4.92,14.64-17.76,14.64Z" />
      </g>
      <g className="logo__garden">
        <path d="M199.33,84.43c2.88,0,3.6,3.72,6.96,3.72,2.4,0,4.92-2.16,4.92-5.04,0-3.12-2.52-5.64-7.08-5.64-4.92,0-8.64,2.76-11.88,7.68-3-2.88-7.08-4.44-11.64-4.44-11.76,0-23.28,10.44-23.28,24.36,0,7.2,3,12.36,7.56,15.36v.36c-11.04,.72-13.68,7.44-13.68,11.52,0,3.6,1.92,6.48,5.16,8.16v.48c-12.12,1.44-17.16,7.44-17.16,13.8,0,8.4,8.04,14.76,24.12,14.76s31.8-8.28,31.8-21.6c0-9.48-7.8-14.04-16.2-14.04-4.2,0-7.68,.24-11.04,.24s-7.68-1.2-7.68-5.28,4.2-6,13.2-5.88h.72c11.64,0,23.16-10.56,23.16-24.48,0-4.44-1.2-8.16-3.12-10.92,1.68-1.92,3.6-3.12,5.16-3.12Zm-33.24,58.32l10.92,.72c6.96,.48,9.84,3.96,9.84,8.16,0,8.16-10.68,12.84-22.92,12.84-10.44,0-15.6-4.56-15.6-10.44,0-6.36,6-12,17.76-11.28Zm7.68-23.88c-3.84,0-6.72-3.12-6.72-9.96,0-11.04,5.64-24.12,13.8-24.12,3.84,0,6.72,3,6.72,9.84,0,11.04-5.64,24.24-13.8,24.24Z" />
        <path d="M247.19,131.59c-3,0-3.72-3.48-2.76-7.68l10.08-42.24h-10.08l-1.8,7.32h-.6c-1.68-4.92-6.12-8.28-12.24-8.28-14.64,0-25.92,20.04-25.92,39.48,0,13.56,6.84,18.6,13.8,18.6,5.88,0,11.88-3.36,15.72-9.96h.48c0,5.64,2.88,9.96,8.52,9.96,7.32,0,12.48-7.08,13.8-15.12h-3c-.96,3.72-2.64,7.92-6,7.92Zm-7.56-29.52l-3.12,12.48c-2.4,9.48-7.44,17.88-14.52,17.88-4.8,0-7.44-3.96-7.44-12.24,0-14.04,7.32-33.48,18.12-33.48,5.52,0,9.48,5.04,6.96,15.36Z" />
        <path d="M306.94,87.55c0-3.72-2.88-6.84-8.28-6.84-8.16,0-13.68,6.48-18.12,17.16h-.72c3.24-11.28,.84-17.16-6.12-17.16-7.8,0-12.72,7.44-14.28,15.48h3c.96-4.32,3.48-7.8,6.48-7.8,2.76,0,3.36,2.76,2.64,6.48-2.04,9.96-5.52,22.92-9.36,36.72-1.2,4.56,.6,7.2,4.32,7.2,3.36,0,5.4-2.16,6.24-5.64l3.96-17.04c2.76-12.12,10.44-27.72,16.8-27.72,3.24,0,4.08,4.56,8.16,4.56,2.64,0,5.28-1.92,5.28-5.4Z" />
        <path d="M358.07,59.47c.96-4.32,1.8-5.52,5.76-5.52h1.92v-3.6h-25.92v3.6h4.8c3.24,0,4.2,1.2,3.24,5.52l-6.48,28.32h-.36c-3-4.2-7.08-7.08-12.96-7.08-14.64,0-25.8,20.04-25.8,39.48,0,13.56,6.84,18.6,13.8,18.6,5.88,0,11.88-3.36,15.72-9.96h.48c0,5.64,2.88,9.96,8.52,9.96,7.32,0,12.48-7.08,13.8-15.12h-3c-.96,3.72-2.64,7.92-6,7.92-3,0-3.36-3.48-2.4-7.68l14.88-64.44Zm-22.8,55.08c-1.92,8.4-7.8,17.88-14.88,17.88-4.8,0-7.44-3.96-7.44-12.24,0-14.04,7.32-33.48,18.12-33.48,4.32,0,7.2,2.52,9.24,5.64l-5.04,22.2Z" />
        <path d="M398.9,91.03c0-6.24-4.44-10.32-11.52-10.32-15.6,0-29.28,19.56-29.28,38.16,0,13.44,7.32,19.92,16.32,19.92,8.04,0,15.84-5.16,19.56-15.12h-2.64c-3.12,4.44-7.68,7.44-12.84,7.44-6.36,0-10.32-4.8-10.32-14.64,0-1.32,.12-2.76,.24-4.2,24.84-3.96,30.48-14.04,30.48-21.24Zm-13.08-4.8c3.6,0,6.24,2.04,6.24,5.76,0,6-6.84,12.48-22.92,16.08,2.16-10.68,8.28-21.84,16.68-21.84Z" />
        <path d="M454.96,123.67c-.96,3.72-2.64,7.92-6,7.92-3,0-3.84-3.48-2.76-7.68l7.08-28.32c1.92-7.92-2.52-14.88-10.92-14.88-9.12,0-15.72,8.16-19.2,15.96h-.6c2.76-10.08,.12-15.96-7.2-15.96-7.8,0-12.72,7.44-14.28,15.48h3c.96-4.32,3.48-7.8,6.48-7.8,2.76,0,3.36,2.76,2.64,6.48-2.04,9.96-5.52,22.92-9.36,36.72-1.2,4.56,.6,7.2,4.32,7.2,3.36,0,5.4-2.16,6.24-5.64l5.04-21.6c2.04-8.64,10.2-23.04,18.48-23.04,3.84,0,6.36,3.12,5.04,8.04l-7.08,27.24c-2.04,8.04,.96,15,8.28,15s12.48-7.08,13.8-15.12h-3Z" />
      </g>
      <path
        className="logo__stalk logo__stalk--bottom"
        d="M32.45,139.83c12.97,8.87,28.65,14.07,45.55,14.07,24.48,0,46.39-10.89,61.21-28.08"
      />
      <path
        className="logo__stalk logo__stalk--top"
        d="M212.84,67.06c13.21-18.45,34.05-31.28,58.43-33.49"
      />
      <path
        className="logo__flower"
        d="M276.79,48.54c2.98,5.15,9.57,6.92,14.72,3.94,1.71-.99,3.03-2.37,3.95-3.97v.02h.04c-.93,1.59-1.46,3.43-1.46,5.41,0,5.95,4.83,10.78,10.78,10.78s10.78-4.83,10.78-10.78c0-1.97-.54-3.82-1.46-5.41h.03v-.02c.92,1.59,2.25,2.98,3.96,3.97,5.15,2.98,11.75,1.21,14.72-3.94,2.98-5.15,1.21-11.75-3.94-14.72-1.71-.99-3.57-1.44-5.41-1.44l.02-.03h0c1.83,0,3.69-.46,5.39-1.45,5.15-2.98,6.92-9.57,3.94-14.72-2.98-5.15-9.57-6.92-14.72-3.94-1.7,.98-3.03,2.36-3.94,3.95l-.02-.03h-.02c.92-1.59,1.45-3.42,1.45-5.38,0-5.95-4.83-10.78-10.78-10.78s-10.78,4.83-10.78,10.78c0,1.96,.53,3.8,1.45,5.38h-.02l-.02,.03c-.91-1.59-2.24-2.97-3.94-3.95-5.15-2.98-11.75-1.21-14.72,3.94-2.98,5.15-1.21,11.75,3.94,14.72,1.7,.98,3.56,1.44,5.39,1.44h0l.02,.04c-1.84,0-3.7,.45-5.41,1.44-5.15,2.98-6.92,9.57-3.94,14.72Zm22.63-25.52c5.15-2.98,11.75-1.21,14.72,3.94,2.98,5.15,1.21,11.75-3.94,14.72-5.15,2.98-11.75,1.21-14.72-3.94-2.98-5.15-1.21-11.75,3.94-14.72Z"
      />
    </svg>
  )
}

export default AnimatedLogo;
import MailerliteForm from './MailerliteForm';
import { motion } from 'framer-motion';

const HoldingPageMailerliteFormWrapper = (props) => {

  const { holdingPage } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="holding-page__mailer-lite-form__wrapper"
    >
      <MailerliteForm {...props} formText={holdingPage} />
    </motion.div>
  )
};

export default HoldingPageMailerliteFormWrapper;
import { useEffect } from 'react';

const PageRowStyles = (props) => {

  const { setStyles, setNavIsLight, row, windowWidth } = props;
  const { value } = row;
  const { color } = value;

  useEffect(() => {
    let foregroundColor = '#ffffff';
    if (color) {
      if (color.indexOf('rgb') === 0) {
        let colorString = color.replace('rgb(', '').replace(')', '');
        const colorArray = colorString.split(',');
        let cumulativeColorValue = 0;
        for (let colorValue of colorArray) {
          cumulativeColorValue += parseFloat(colorValue);
        }

        if (cumulativeColorValue / 3 < 255 / 2) {
          foregroundColor = '#ffffff';
        } else {
          foregroundColor = '#000000';
        }
      }
    }
    setStyles({
      backgroundColor: color ? color : null,
      color: foregroundColor
    });
  }, [windowWidth, setStyles, color, setNavIsLight]);
}

export default PageRowStyles;
import { useEffect } from 'react';
import lerpArray from '../../../../utils/lerpArray';
import { useFrame } from '@react-three/fiber';

const SceneNavigation = (props) => {

  const {
    curve, homepage, itemSpace, targetPosition, targetRotation, elapsedTime, scrollDistance,
    setScrollDistance, userIsTouching, camera, windowHeight, setScrollDistanceBeforeStandardScroll, isStandardScroll
  } = props;

  useEffect(() => {
    if (homepage && isStandardScroll === false) {
      setScrollDistance(homepage ? homepage.length * itemSpace : windowHeight);
    }
  }, [homepage, itemSpace, scrollDistance, setScrollDistance, setScrollDistanceBeforeStandardScroll, windowHeight, isStandardScroll]);
  
  useEffect(() => {
    if (curve.points) {
      targetPosition.current = curve.getPoint(0);
      targetRotation.current = curve.getTangent(0);
    }
  }, [curve, targetPosition, targetRotation]);

  useFrame(({ clock }) => {
    if (isStandardScroll === false) {
      elapsedTime.current = clock.elapsedTime;

      let lerpPositionAmount = 0.06;
      if (userIsTouching === true) {
        lerpPositionAmount = 0.02;
      }

      let lerpRotationAmount = 0.1;
      if (userIsTouching === true) {
        lerpRotationAmount = 0.1;
      }

      const newCameraPositionArray = lerpArray(
        [camera.current.position.x, camera.current.position.y, camera.current.position.z],
        [targetPosition.current.x, targetPosition.current.y, targetPosition.current.z],
        lerpPositionAmount
      );

      const newCameraRotationArray = lerpArray(
        [camera.current.rotation.y, camera.current.rotation.x, camera.current.rotation.z],
        [targetRotation.current.x, targetRotation.current.y, targetRotation.current.z],
        lerpRotationAmount
      );

      camera.current.position.x = -newCameraPositionArray[0];
      camera.current.position.y = 7;
      camera.current.position.z = newCameraPositionArray[2];

      camera.current.rotation.x = 0.05;
      camera.current.rotation.y = newCameraRotationArray[0] * 1.01;
    }
  });

  return null;
}

export default SceneNavigation;